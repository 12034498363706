import React from 'react';

const NewsItem = ({ title, date, category, image, link }) => (
  <div className="news__grid__item">
    <a href={link} alt={title}>
      <img src={image} alt={title} />
    </a>
    <div className="news__grid__content">
      <span className="news__grid__item__date">{date}</span>
      <strong className="news__grid__item__title">{title}</strong>
      <br />
      <span className="news__grid__item__category">{category}</span>
    </div>
  </div>
)

export default NewsItem
