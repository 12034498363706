import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Rocket = () => {
  const data = useStaticQuery(graphql`
    query{
      rocket: file(relativePath: {eq: "rocket.png"}){
        childImageSharp{
          fixed(width: 100){
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

 return <Img fixed={data.rocket.childImageSharp.fixed} alt="Aceleração" />

}

export default Rocket
