import React from 'react'

import DMRN from '../images/dmrn'

const Events = () => (
  <div className="events" id="events">
    <div className="events__image"><DMRN /></div>
    <div className="events__content">
      Evento voltado para empresários, investidores, entusiastas de tecnologia e estudantes
      que tenham o interesse em conhecer as startups que vem surgindo no Rio Grande do Norte,
      as tecnologias usadas, bem como entender um pouco mais sobre robótica e impressão 3D.
   <br />
      <br />
      <span><a href="https://www.youtube.com/watch?v=UapEhcMYkAk" alt="Evntos">Mais detalhes</a></span>
    </div>

    <div className="events__more">
      <a href="https://www.youtube.com/watch?v=UapEhcMYkAk">
        <div className="events__more__icon"><i className="fas fa-play"></i></div>
        <span>Veja como foi o evento</span>
      </a>
    </div>
  </div>
)

export default Events;
