import React from 'react';
import CompanyImg from '../images/sections/company'
import '../../styles/company.scss'

const Company = () => (
 <div className="company" id="about">

  <div className="company__content__img">
   <CompanyImg />
  </div>

  <div className="company__content">
   <h1 className="company__title">A Empresa</h1>
   <div className="company__paragraph">
    A Incubatech é uma empresa de aceleração
    de startups com foco no desenvolvimento
    de softwares sob medida e que busca transformar
    ideias em negócios rentáveis, estimulando
    o empreendedorismo e a inovação. O nosso objetivo
    é solucionar problemas complexos  e inserir soluções
    inovadoras no mercado por meio do nosso programa
    de aceleração, o qual possui como maior diferencial
    a agilidade no processo de desenvolvimento e as conexões
    empresariais que levam à evolução do seu negócio.
   </div>
  </div>

 </div>
)

export default Company