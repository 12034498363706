import React from 'react'

import NewsItem from './newsItem'

const NewsList = ({ news }) => (
  <div className="news__grid">
    {news.map((item, index) => (
      <NewsItem
        date={item.date}
        title={item.title}
        category={item.category}
        image={item.image}
        link={item.link}
        key={index} />
    ))}
  </div>
)

export default NewsList
