import React from "react"
import Layout from "../components/layout"

import Company from '../components/sections/company'
import Citacions from '../components/sections/citacions'
import Services from '../components/sections/services'
//import Team from '../components/team'
import Projects from '../components/projects'
import News from '../components/news'
import Parterns from '../components/partners'
import Contact from '../components/contact'

const IndexPage = () => (
  <Layout>
    <Company />
    <Citacions />
    <Services />
    <Projects />
    <News />
    <Contact />
    <Parterns />
  </Layout>
)

export default IndexPage
