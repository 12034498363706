import React from 'react'

import ServicesImg from '../images/sections/services'
import '../../styles/services.scss'

import RocketImg from '../images/icons/rocket'
import BezierImg from '../images/icons/bezier'
import NetworkingImg from '../images/icons/networking'


const Services = () => (
  <div className="services" id="services">
    <div className="services__row">

      <div className="services__content">

        <h1 className="services__title">Serviços</h1>

        <div className="services__paragraph">
          Com o objetivo de fomentar o empreendedorismo, a Incubatech
          disponibiliza conexões empresariais e um espaço criativo para
          aqueles que já possuem uma startup em andamento ou desejam iniciar
          um novo projeto. Nossa empresa oferece serviços de desenvolvimento
          de softwares nas mais diversas áreas, entre elas: sistemas web,
          softwares desktop, aplicativos mobile, design gráfico e design web.
          A Incubatech também oferece serviços de consultoria de pessoas e
          vendas, nos quais são aplicados estratégias adaptadas para jovens
          empresas, acompanhando-as em todo o processo de aceleração com o
          intuito de garantir a aplicação das melhores práticas de gestão.
        </div>
      </div>

      <div>
        <ServicesImg />
      </div>

    </div>

    <div className="services__grid">
      <div className="services__grid__item services__rocket">
        <RocketImg />
        <span>Aceleração</span>
      </div>
      <div className="services__grid__item services__bezier">
        <BezierImg />
        <span>Design</span>
      </div>
      <div className="services__grid__item services__networking">
        <NetworkingImg />
        <span>Desenvolvimento</span>
      </div>
    </div>

  </div>
)

export default Services;

/*

  <div className="services__content__grid">
   <div className="services__item services__rocket">
    <RocketImg />
    <span>Aceleração</span>
    </div>

   <div className="services__item services__bezier">
    <BezierImg />
    <span>Design</span>
   </div>

   <div className="services__item services__networking">
    <NetworkingImg />
    <span>Desenvolvimento</span>
   </div>
  </div>
*/
