import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const News = () => {
  const data = useStaticQuery(graphql`
    query{
      news: file(relativePath: {eq: "section-f.png"}){
        childImageSharp{
          fixed(width: 300){
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return <Img fixed={data.news.childImageSharp.fixed} alt="Noticias" />

}

export default News
