import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import EmailImg from '../components/images/icons/email'
import '../styles/contact.scss'

const Contact = () => {

  const CONTACT_END_POINT = "https://faceponto.com.br/publico/contact_incubatech"

  const [isSent, setSent] = useState(false)
  const [isSentError, setSentError] = useState(false)
  const [Loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      text: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(30, 'O limite para este formulário é 30 caracteres ou menos')
        .required('Insira um nome válido. Este campo também é obrigatório ser preenchido.'),
      email: Yup.string().email('E-mail inserido neste formulário está incorreto')
        .required('É preciso de um e-mail válido! Este campo também é obrigatório ser preenchido.'),
      text: Yup.string().max(360, 'Tem que haver 359 caracteres ou menos')
        .required('O campo de texto é obrigatório!')
    }),
    onSubmit: values => {
      setLoading(true)
      axios.post(CONTACT_END_POINT, { ...values })
        .then(res => {
          setSent(true)
          setLoading(false)
          return [JSON.stringify(values, null, 2)]
        })
        .catch(() => {
          setSentError(true)
          setLoading(false)
        })
    }
  })

  useEffect(() => {
    if (isSent) {
      setTimeout(() => setSent(false), 5000)
    }
  })

  return (
    <section className="contact" id="contact">

      <div className="contact__form">
        <form onSubmit={formik.handleSubmit}>
          <div className="contact__title">
            <EmailImg />
            <h1>Entre em contato conosco</h1>
          </div>

          <input
            id="name"
            name="name"
            type="text"
            placeholder="Nome Completo *"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ?
            (<div className="contact__form__required">{formik.errors.name}</div>)
            : null}

          <input
            id="email"
            name="email"
            type="email"
            placeholder="E-mail *"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ?
            (<div className="contact__form__required">{formik.errors.email}</div>)
            : null}

          <textarea
            id="text"
            name="text"
            type="text"
            placeholder="Mensagem..."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.text}
          ></textarea>
          {formik.touched.text && formik.errors.text ?
            (<div className="contact__form__required">{formik.errors.text}</div>)
            : null}

          <button>
            {Loading ?
              (<div className="loading">
                <svg height="30" width="30" className="svg-fix">
                  <circle cx="15" cy="15" r="10" stroke="white" stroke-width="5" fill="transparent" />
                </svg>
              </div>)
              :
              (<span>Enviar</span>)
            }
          </button>

          {isSent ?
            <div className="contact__success" style={isSent ? { display: 'flex' } : { display: 'none' }} onClick={() => setSent(false)} onKeyDown={() => setSent(false)}>
              <span role="img" aria-label="Envelope with Arrow">📩 Mensagem Enviada!</span>
              <div className="contact__close">X</div>
            </div>
            :
            <></>}

          {isSentError ?
            <div className="contact__error" onClick={() => setSentError(false)} onKeyDown={() => setSentError(false)}>
              <span role="img" aria-label=" Anxious Face with Sweat">😰 Ocorreu algum problema ao enviar...</span>
              <div className="contact__close">X</div>
            </div>
            :
            <></>}

        </form>
      </div>

      <div className="contact__map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3969.2332793177206!2d-35.20283928523286!3d-5.822692295780886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdf3f24a34351d22c!2sIncubatech!5e0!3m2!1sen!2sus!4v1532726570183"
          title="Locailização Incubatech"
          frameBorder="0"
          width="600"
          height="490"
        >
        </iframe>
      </div>

    </section>
  );
};

export default Contact;
