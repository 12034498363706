import news1 from '../images/news/news-1.png'
import news2 from '../images/news/news-2.png'
import news3 from '../images/news/news-3.png'
import news4 from '../images/news/news-4.png'
import news5 from '../images/news/news-5.png'
import news6 from '../images/news/news-6.png'

const News = [
  {
    date: "02/02/2018",
    title: "Entrevista no Bom Dia RN",
    link: "https://globoplay.globo.com/v/6949182/",
    category: "Bom Dia RN",
    image: news1,
  },
  {
    date: "02/02/2018",
    title: "Boa Tarde Cidadão: Alexandre Dalmax e Cássio Leandro",
    link: "https://www.youtube.com/watch?v=EoWk4UaD2ic",
    category: "YouTube",
    image: news2,
  },
  {
    date: "02/02/2018",
    title: "Direito Digital e Novas Tecnologias: o presente e o futuro da advocacia",
    link: "https://www.instagram.com/p/Blokf2qh5Fi/?utm_source=ig_share_sheet&igshid=8p8b5md8qwfy&r=wa1",
    category: "Instagram",
    image: news3,
  },
  {
    date: "02/02/2018",
    title: "Entrevista na TV Assembléia",
    link: "https://youtube.com/watch?v=r3uUcPCzyMg&feature=youtu.be",
    category: "YouTube",
    image: news4,
  },
  {
    date: "02/02/2018",
    title: "Sistema inovador facilita avaliação de pacientes por nutricionistas ",
    link: "https://novonoticias.com/cotidiano/sistema-inovador-facilita-avaliacao-de-pacientes-por-nutricionistas/",
    category: "Novo Jornalismo",
    image: news5,
  },
  {
    date: "02/02/2018",
    title: "TV Câmara: Entrevista sobre o Disruptive Meeting",
    link: "https://youtu.be/97nQP3eI5_M?t=903",
    category: "TV Câmara",
    image: news6,
  },
]

export default News