import React from 'react';

import ProjectList from '../projects/projectList'

import projects from '../../data/projects'
import '../../styles/projects.scss'
import ProjectsImg from '../images/sections/projects'


const Projects = () => (
  <section className="projects" id="projects">

    <div className="projects__top">
      <ProjectsImg />
    </div>

    <h1>Projetos</h1>

    <span>Conheça alguns dos nossos projetos</span>

    <ProjectList projects={projects} />

  </section>
)

export default Projects;
