import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const DMRN = () => {
 const data = useStaticQuery(graphql`
    query{
      dmrn: file(relativePath: {eq: "dmrn.png"}){
        childImageSharp{
          fixed(width: 200){
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

 return <Img fixed={data.dmrn.childImageSharp.fixed} alt="DMRN" />

}

export default DMRN
