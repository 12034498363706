import React from 'react'

import '../../styles/partners.scss'
import PartnersList from './partnersList'

const Partners = () => (
 <section className="partners">

  <div className="partners__title">
   <h3>PARCEIROS</h3>
  </div>

  <div className="partners">
   <PartnersList />
  </div>

 </section>
)

export default Partners
