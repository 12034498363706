import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Projects = () => {
  const data = useStaticQuery(graphql`
    query{
      projects: file(relativePath: {eq: "section-d.png"}){
        childImageSharp{
          fixed(width: 300){
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

 return <Img fixed={data.projects.childImageSharp.fixed} alt="Projects" />

}

export default Projects
