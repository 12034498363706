import React from 'react'

import NewsList from '../news/newsList'
import NewsImg from '../images/sections/news'
import NewsData from '../../data/news'
import Events from '../news/events'
import '../../styles/news.scss'

const News = () => (
 <div className="news" id="media">

  <NewsImg />
  <h1>Notícias</h1>

  <NewsList news={NewsData} />

  <Events />
 </div>
)

export default News
