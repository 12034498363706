import React from 'react';

import '../../styles/citacions.scss'

const Citacions = () => (
  <div className="citacions">
    <div className="citacions__content">
      <h3>"Pessoas, Processos e Produto."</h3>
      <span>- Marcus Lemonis</span>
    </div>
    <div className="citacions__cover"></div>
  </div>
)

export default Citacions;
